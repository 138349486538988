<template>
    <v-card v-if="hasNotification" elevation="0" color="#7466CC" class="rounded-0">
        <div class="container">
            <v-row>
                <v-col cols="12" class="my-auto d-flex">
                    <v-icon color="#ffffff" left>fas fa-bell</v-icon>
                    <p class="text-white mb-0">
                        {{ notification.text }}
                        <v-btn text :key="key" v-for="(option, key) in notification.options"
                            @click="doNotifcationAction(option.to)">{{ option.label }}</v-btn>
                    </p>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import api from "../services/api";
import config from '../config';
export default {
    props: {
        notifications: Array,
    },
    data: () => ({
        handledNotification: {},
        hasNotification: false,
        notification: {
            text: "",
            options: [
                {
                    'label': "",
                    'to': ""
                }
            ]
        }
    }),
    watch: {
        notifications(notifications) {
            this.handledNotification = notifications[0];
            this.setNotification();
            if (this.handledNotification !== undefined) {
                this.hasNotification = true;
            }
        }
    },

    methods: {
        doNotifcationAction(to) {
            api.patch(`${config.url.api}/v1/notifications/${this.handledNotification.slug}`).then((res) => {
                if (res.data.success) {
                    this.$emit('dorefresh')
                }
            }).catch((err) => {
                console.log(err);
            })

            this.$router.push(to);
        },
        setNotification() {
            if(this.handledNotification){
                switch (this.handledNotification.classification) {
                    case 'project_invite':
                        this.notification.text = "U bent uitgenodigd om deel te nemen aan een project:";
                        this.notification.options = [{
                            'label': "Accepteren",
                            'to': `/project-uitnodiging/${this.handledNotification.data.slug}/accepteren`
                        },
                        {
                            'label': "Afwijzen",
                            'to': `/project-uitnodiging/${this.handledNotification.data.slug}/afwijzen`
    
                        }]
                        break;
                     case 'program_invite':
                        this.notification.text = "U bent uitgenodigd om deel te nemen aan een programma:";
                        this.notification.options = [{
                            'label': "Accepteren",
                            'to': `/programma-uitnodiging/${this.handledNotification.data.slug}/accepteren`
                        },
                        {
                            'label': "Afwijzen",
                            'to': `/programma-uitnodiging/${this.handledNotification.data.slug}/afwijzen`
    
                        }]
                        break;
                    case 'monitoringsplan_full':
                        this.notification.text = "Er is een nieuw monitoringsplan ingeleverd:";
                        this.notification.options = [{
                            'label': "Bekijken",
                            'to': `/project/${this.handledNotification.data.slug}/monitoringsplan`
                        }]
                        break;
                    case 'transformation_canvas_full':
                        this.notification.text = "Er is een nieuw transformatie canvas ingeleverd:";
                        this.notification.options = [{
                            'label': "Bekijken",
                            'to': `/project/${this.handledNotification.data.slug}/transformatie-canvas`
                        }]
                        break;
                    case 'counting_and_telling_partially':
                        this.notification.text = "Er is een nieuw tellen en vertellen formulier ingeleverd:";
                        this.notification.options = [{
                            'label': "Bekijken",
                            'to': `/project/${this.handledNotification.data.slug}/tellen-en-vertellen`
                        }]
                        break;
                    case 'counting_and_telling_full':
                        this.notification.text = "Alle tellen en vertellen formulieren zijn ingeleverd:";
                        this.notification.options = [{
                            'label': "Bekijken",
                            'to': `/project/${this.handledNotification.data.slug}/tellen-en-vertellen`
                        }]
                        break;
                    case 'dialogue_form_program_full':
                        this.notification.text = "Er is een nieuw dialoog formulier programma ingeleverd:";
                        this.notification.options = [{
                            'label': "Bekijken",
                            'to': `/project/${this.handledNotification.data.slug}`
                        }]
                        break;
                    case 'findings_full':
                        this.notification.text = "Er is een nieuwe bevinding ingeleverd:";
                        this.notification.options = [{
                            'label': "Bekijken",
                            'to': `/bevindingen/${this.handledNotification.data.slug}`
                        }]
                        break;
                    default:
                        break;
                }
            }
        }
    }
}

</script>