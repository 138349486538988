<template>
	<v-dialog v-model="dialog" content-class="theme-popup">
		<div>
			<div class="text-right">
				<v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
					<v-icon>fas fa-times </v-icon>
				</v-btn>
			</div>
			<div class="text-center pb-5">
				<h3>Geef uw Dialoogformulier programma een naam</h3>
			</div>

			<label for="">Dialoogformulier programma naam </label>
			<v-text-field class="theme-input" solo title="Dialoogformulier programma naam" v-model="data.title"></v-text-field>
		</div>
		<div class="text-center pt-5">
			<v-btn class="btn" color="themepurple" @click="submit" dark>Volgende</v-btn>
		</div>
	</v-dialog>
</template>
<script>
import config from '../../config';
import api from "../../services/api";
export default {
	props: {
		dialog: Boolean,
	},
	data: () => ({
		data: {
			title: "",
			period: "",
			investment: "",
			successes_and_worries: "",
			ambiton_effect: "",
			are_we_doing_good_work: "",
			are_we_missing_something: "",
			attention_subject: "",
			perspective_parents: {
				intrest_paramount: "",
				actively_involved: "",
				empowerment_power_responsibility: "",
			},
			perspective_professional: {
				collaboration_improvement: "",
				regulatory_burden: "",
			},
			cost_regulation: {
				effective_aid: "",
				unnecessary_aid: "",
			},
			quality_improvement: {
				closer_to_home: "",
				right_moment: "",
			},
			are_we_doing_good_things: "",
			are_we_missing_things: "",
			effort_ambition_contribution: ""
		}
	}),
	methods: {
		submit() {
			api.post(`${config.url.api}/v1/dialogueforms/0/program`, this.data).then((res) => {
				if (res.data.success) {
					//redirect to the newly created recource.
					this.$toast.success('Dialoogformulier programma succesvol aangemaakt.')
					this.$router.push("/dialoogformulier-programma/" + res.data.slug)

				} else {
					this.$toast.error('Er ging iets mis bij het aanmaken van je dialoogformulier programma.')
				}
			}).catch(() => {
				this.$toast.error('Er ging iets mis bij het aanmaken van je dialoogformulier programma.')
			})
		}
	}
}

</script>

<style></style>