<template>
    <v-dialog v-model="dialog" content-class="theme-popup">
        <div>
            <div class="text-right">
                <v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
                    <v-icon>fas fa-times </v-icon>
                </v-btn>
            </div>
            <div class="text-center pb-5">
                <h3>Geef uw project een naam</h3>
            </div>

            <label for="">Projectnaam </label>
            <v-text-field class="theme-input" solo title="Project naam" v-model="data.title"></v-text-field>
        </div>
        <div class="text-center pt-5">
            <v-btn class="btn" color="themepurple" @click="submit" dark>Volgende</v-btn>
        </div>
    </v-dialog>
</template>
<script>
import config from '../../config';
import api from "../../services/api";
export default {
    props: {
        dialog: Boolean,
    },
    data: () => ({
        data: {
            title: "",
            description: "Dit is een omschrijving voor je project, Deze kun je aanpassen."
        }
    }),
    methods: {
        submit() {
            api.post(`${config.url.api}/v1/projects/`, this.data).then((res) => {
                if (res.data.success) {
                    //redirect to the newly created recource.
                    this.$toast.success('Project succesvol aangemaakt.')
                    this.$router.push("/project/" + res.data.slug)

                } else {
                    this.$toast.error('Er ging iets mis bij het aanmaken van je project.')
                }
            }).catch(() => {
                this.$toast.error('Er ging iets mis bij het aanmaken van je project.')
            })
        }
    }
}

</script>

<style></style>