<template>
	<v-app>
		<topmenubar />
		<v-main class="pt-0 main-top-card-border">
			<notifications :notifications="notifications" @dorefresh="getNotifications()" />

			<v-card elevation="0" color="#F8F8F9" class="py-8 dashboard-info-card">
				<div class="container">
					<v-row>
						<v-col cols="12" md="5">
							<h1>Dashboard</h1>
							<p>Hier vind u een overzicht van uw projecten, bevindingen, dialoog formulieren en moties. Dit is afhankelijk van uw geselecteerde functie en programma.</p>
						</v-col>
						<v-col cols="12" md="7" class="text-right ml-auto my-auto">
							<v-btn @click="inviteuserdialog = true" class="mr-3 btn" outlined color="themepurple">Uitnodiging Sturen</v-btn>
							<v-btn v-if="user.role == 'PM' || user.staff" @click="createprojectdialog = true" class="text-white btn" color="themepurple"> <v-icon left>fas fa-plus-circle</v-icon> Nieuw project starten</v-btn>
						</v-col>
					</v-row>
				</div>
			</v-card>
			<div class="container py-15">
				<v-row>
					<v-col cols="12" md="3" v-if="user.role == 'PM' || user.role == 'PU' || user.staff">
						<h2>
							<v-btn v-if="user.role == 'PM' || user.staff" @click="createprojectdialog = true" class="mr-2" elevation="0" icon color="themepurple"><v-icon>fas fa-plus-circle</v-icon></v-btn>
							Projecten
						</h2>
						<v-divider class="my-5"></v-divider>

						<v-skeleton-loader v-if="!ready.projects" type="list-item-three-line"></v-skeleton-loader>
						<v-list v-if="ready.projects">
							<v-list-item :to="item.to" two-line v-for="item in projects" :key="item.id">
								<v-list-item-content>
									<v-list-item-title class="text-purple fs-20">{{ item.title }}</v-list-item-title>

									<v-list-item-subtitle>
										Gestart:
										{{
											new Date(item.datestarted * 1000).toLocaleDateString("nl-NL", {
												timeZone: "Europe/Amsterdam",
											})
										}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>

					<v-col cols="12" md="3" v-if="user.role == 'OG' || user.staff">
						<h2>
							<v-btn v-if="user.role == 'OG' || user.staff" @click="startdialogueformprogramdialog = true" class="mr-2" elevation="0" icon color="themepurple"><v-icon>fas fa-plus-circle</v-icon></v-btn>
							Dialoogformulieren programma
						</h2>
						<v-divider class="my-5"></v-divider>

						<v-skeleton-loader v-if="!ready.dialogformsprogram" type="list-item-three-line"></v-skeleton-loader>
						<v-list v-if="ready.dialogformsprogram">
							<v-list-item :to="item.to" two-line v-for="item in dialogformsprogram" :key="item.id">
								<v-list-item-content>
									<v-list-item-title class="text-purple fs-20">{{ item.title }}</v-list-item-title>

									<v-list-item-subtitle>
										Gestart:
										{{
											new Date(item.datestarted * 1000).toLocaleDateString("nl-NL", {
												timeZone: "Europe/Amsterdam",
											})
										}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>

					<v-col cols="12" md="3" v-if="user.role == 'PM' || user.role == 'OG' || user.staff">
						<h2>
							<v-btn v-if="user.role == 'PM' || user.staff" @click="startdialogueformlinesdialog = true" class="mr-2" elevation="0" icon color="themepurple"><v-icon>fas fa-plus-circle</v-icon></v-btn>
							Dialoogformulieren lijnen
						</h2>
						<v-divider class="my-5"></v-divider>

						<v-skeleton-loader v-if="!ready.dialogformslines" type="list-item-three-line"></v-skeleton-loader>
						<v-list v-if="ready.dialogformslines">
							<v-list-item :to="item.to" two-line v-for="item in dialogformslines" :key="item.id">
								<v-list-item-content>
									<v-list-item-title class="text-purple fs-20">{{ item.title }}</v-list-item-title>
									<v-list-item-subtitle>
										Gestart:
										{{
											new Date(item.datestarted * 1000).toLocaleDateString("nl-NL", {
												timeZone: "Europe/Amsterdam",
											})
										}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>

					<v-col cols="12" md="3" v-if="user.role == 'PM' || user.role == 'OG' || user.staff">
						<h2>
							<v-btn @click="createfindingdialog = true" class="mr-2" elevation="0" icon color="themepurple"><v-icon>fas fa-plus-circle</v-icon></v-btn>
							Bevindingen
						</h2>
						<v-divider class="my-5"></v-divider>
						<v-skeleton-loader v-if="!ready.findings" type="list-item-three-line"></v-skeleton-loader>
						<v-list v-if="(ready.findings && user.role == 'OG') || (ready.findings && user.role == 'PM') || (ready.findings && user.staff)">
							<v-list-item :to="item.to" two-line v-for="item in findings" :key="item.id">
								<v-list-item-content>
									<v-list-item-title class="text-purple fs-20">{{ item.title }}</v-list-item-title>
									<v-list-item-subtitle>
										Gestart:
										{{
											new Date(item.datefilled * 1000).toLocaleDateString("nl-NL", {
												timeZone: "Europe/Amsterdam",
											})
										}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>

					<v-col cols="12" md="3" v-if="user.role == 'PM' || user.role == 'OG' || user.staff">
						<h2>
							<v-btn @click="startmotiondialog = true" class="mr-2" elevation="0" icon color="themepurple"><v-icon>fas fa-plus-circle</v-icon></v-btn>
							Moties
						</h2>
						<v-divider class="my-5"></v-divider>

						<v-skeleton-loader v-if="!ready.motions" type="list-item-three-line"></v-skeleton-loader>
						<v-list v-if="(ready.motions && user.role == 'PM') || (ready.motions && user.staff)">
							<v-list-item :to="item.to" two-line v-for="item in motions" :key="item.id">
								<v-list-item-content>
									<v-list-item-title class="text-purple fs-20">{{ item.title }}</v-list-item-title>
									<v-list-item-subtitle>
										Gestart:
										{{
											new Date(item.datefilled * 1000).toLocaleDateString("nl-NL", {
												timeZone: "Europe/Amsterdam",
											})
										}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
				</v-row>
			</div>
		</v-main>

		<!-- Project invite dialog -->
		<invitedialog :dialog="inviteuserdialog" @close-dialog="inviteuserdialog = false" />

		<!-- Start project dialog -->
		<newprojectdialog :dialog="createprojectdialog" @close-dialog="createprojectdialog = false" />

		<!-- Start finding dialog -->
		<newfindingdialog :dialog="createfindingdialog" @close-dialog="createfindingdialog = false" />

		<!-- Start newdialogueformprogram dialog -->
		<newdialogueformprogram :dialog="startdialogueformprogramdialog" @close-dialog="startdialogueformprogramdialog = false" />

		<!-- create dialogue form lines dialog -->
		<v-dialog v-model="startdialogueformlinesdialog" content-class="theme-popup">
			<div>
				<div class="text-right">
					<v-btn @click="startdialogueformlinesdialog = false" icon class="" elevation="0" color="themepurple">
						<v-icon>fas fa-times </v-icon>
					</v-btn>
				</div>
				<div class="text-center pb-5">
					<h3>Dialoog formulier lijnen Toevoegen</h3>
				</div>
				<label for="">Project(en) kiezen om aan uw Dialoog formulier lijnen te koppelen. </label>
				<v-select class="theme-input" solo :items="projects" multiple v-model="dialogformslinespopup.projects" label="Selecteer één of meerdere projecten" item-text="title" return-object></v-select>
			</div>
			<div class="text-center pt-5">
				<v-btn class="btn" @click="createDialogueFormLines()" color="themepurple" dark>Verder gaan</v-btn>
			</div>
		</v-dialog>

		<!-- create motion dialog -->
		<v-dialog v-model="startmotiondialog" content-class="theme-popup">
			<div>
				<div class="text-right">
					<v-btn @click="startmotiondialog = false" icon class="" elevation="0" color="themepurple">
						<v-icon>fas fa-times </v-icon>
					</v-btn>
				</div>
				<div class="text-center pb-5">
					<h3>Motie Toevoegen</h3>
				</div>

				<label for="">Project(en) kiezen om aan uw motie te koppelen. </label>
				<v-select class="theme-input" solo :items="projects" multiple v-model="motionpopup.projects" label="Selecteer één of meerdere projecten" item-text="title" return-object></v-select>
			</div>
			<div class="text-center pt-5">
				<v-btn class="btn" @click="createMotion()" color="themepurple" dark>Verder gaan</v-btn>
			</div>
		</v-dialog>
	</v-app>
</template>

<script>

import topmenubar from "@/components/topmenubar.vue";
import notifications from "@/components/notification.vue";

import newprojectdialog from "@/components/dialogs/newproject.vue";
import newfindingdialog from "@/components/dialogs/newfinding.vue";
import newdialogueformprogram from "@/components/dialogs/newdialogueformprogram.vue";


import invitedialog from "@/components/dialogs/project/invite.vue";

import api from "../services/api";
import config from '../config'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({ user: 'getuser' }),
	},
	name: "Dashboard",
	components: {
		topmenubar, newprojectdialog, invitedialog, notifications, newfindingdialog, newdialogueformprogram
	},

	data: () => ({
		inviteuserdialog: false,
		createprojectdialog: false,
		createfindingdialog: false,
		startmotiondialog: false,
		startdialogueformlinesdialog: false,
		startdialogueformprogramdialog: false,
		notifications: [],
		ready: {
			projects: false,
			findings: false,
			motions: false,
			dialogformsprogram: false,
			dialogformslines: false,
		},
		projects: [
		],
		motions: [
		],
		findings: [
		],
		dialogformsprogram: [
		],
		dialogformslines: [
		],
		dialogformslinespopup: {
			projects: []
		},
		motionpopup: {
			projects: []
		}
	}),
	mounted() {
		this.getNotifications();
		if (this.user.role == 'PM' || this.user.role == 'PU' || this.user.staff) {
			this.getProjects();
		}
		if (this.user.role == 'PM' || this.user.role == 'OG' || this.user.staff) {
			this.getFindings();
			this.getDialogueFormLines();
			this.getMotions();
		}

		if (this.user.role == 'OG' || this.user.staff) {
			this.getDialogueFormProgram();
		}

	},
	methods: {
		getNotifications() {
			api.get(`${config.url.api}/v1/notifications/`).then((res) => {
				this.notifications = res.data.data;
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van de projecten.')
						break;
				}
			})
		},
		getProjects() {
			api.get(`${config.url.api}/v1/projects/0`).then((res) => {
				res.data.data.forEach(project => {
					this.projects.push({
						id: project.project,
						title: project.title,
						datestarted: project.created_at,
						to: `/project/${project.slug}`
					});
				});
				this.ready.projects = true;
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van de projecten.')
						break;
				}
			})

		},
		getFindings() {
			api.get(`${config.url.api}/v1/findings/0`).then((res) => {
				res.data.data.forEach(form => {
					console.log(form);
					this.findings.push({
						title: form.title,
						datefilled: form.created_at,
						to: `/bevindingen/${form.slug}`
					});
				});
				this.ready.findings = true;
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van de bevindingen.')
						break;
				}
			})

		},
		getMotions() {
			api.get(`${config.url.api}/v1/motions/0`).then((res) => {
				res.data.data.forEach(motion => {
					this.motions.push({
						title: motion.title,
						datefilled: motion.created_at,
						to: `/transformatie-motie/${motion.slug}`
					});
				});

				this.ready.motions = true;
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van de moties.')
						break;
				}
			})
		},
		getDialogueFormLines() {
			api.get(`${config.url.api}/v1/dialogueforms/0/lines`).then((res) => {
				res.data.data.forEach(form => {
					this.dialogformslines.push({
						title: form.title,
						datestarted: form.created_at,
						to: `/dialoogformulier-lijnen/${form.slug}`
					});
				});
				this.ready.dialogformslines = true;
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van de dialooglijn formulieren.')
						break;
				}
			})

		},

		getDialogueFormProgram() {
			api.get(`${config.url.api}/v1/dialogueforms/0/program`).then((res) => {
				res.data.data.forEach(form => {
					this.dialogformsprogram.push({
						title: form.title,
						datestarted: form.created_at,
						to: `/dialoogformulier-programma/${form.slug}`
					});
				});
				this.ready.dialogformsprogram = true;
			}).catch((err) => {
				switch (err.response.status) {
					default:
						this.$toast.error('Fout bij het ophalen van de dialoogprogramma formulieren.')
						break;
				}
			})

		},
		createDialogueFormLines() {
			var url_parameters = "?projects=";
			this.dialogformslinespopup.projects.forEach(project => {
				url_parameters = url_parameters + project.id + ",";
			});
			this.$router.push("/dialoogformulier-lijnen/nieuw" + url_parameters)
		},
		createMotion() {
			var url_parameters = "?projects=";
			this.motionpopup.projects.forEach(project => {
				url_parameters = url_parameters + project.id + ",";
			});
			this.$router.push("/transformatie-motie/nieuw" + url_parameters)
		}
	}
};
</script>

