<template>
    <v-dialog v-model="dialog" content-class="theme-popup">
        <div>
            <div class="text-right">
                <v-btn @click="$emit('close-dialog')" icon class="" elevation="0" color="themepurple">
                    <v-icon>fas fa-times </v-icon>
                </v-btn>
            </div>
            <div class="text-center pb-5">
                <h3>Geef uw bevinding een naam</h3>
            </div>

            <label for="">Bevindingnaam </label>
            <v-text-field class="theme-input" solo title="Project naam" v-model="data.title"></v-text-field>
        </div>
        <div class="text-center pt-5">
            <v-btn class="btn" color="themepurple" @click="submit" dark>Volgende</v-btn>
        </div>
    </v-dialog>
</template>
<script>
import config from '../../config';
import api from "../../services/api";
export default {
    props: {
        dialog: Boolean,
    },
    data: () => ({
        data: {
           title: "",
				period: "",
				investment: "",
				successes_and_worries: "",
				are_we_doing_good_work: "",
				are_we_missing_something: "",
				ambition_effect: "",
				attention_subject: [
					"", "", "", "", "",
				],
				perspective_parents: {
					intrest_paramount: "",
					actively_involved: "",
					empowerment_power_responsibility: "",
				},
				perspective_professional: {
					collaboration_improvement: "",
					regulatory_burden: "",
				},
				cost_regulation: {
					effective_aid: "",
					unnecessary_aid: "",
				},
				quality_improvement: {
					closer_to_home: "",
					right_moment: "",
				},
				improvements: {
					are_we_doing_good_things: "",
					are_we_missing_things: "",
					effort_ambition_contribution: ""
				},
        }
    }),
    methods: {
        submit() {
            api.post(`${config.url.api}/v1/findings/`, this.data).then((res) => {
                if (res.data.success) {
                    //redirect to the newly created recource.
                    this.$toast.success('Bevinding succesvol aangemaakt.')
                    this.$router.push("/bevindingen/" + res.data.slug)

                } else {
                    this.$toast.error('Er ging iets mis bij het aanmaken van je bevinding.')
                }
            }).catch(() => {
                this.$toast.error('Er ging iets mis bij het aanmaken van je bevinding.')
            })
        }
    }
}

</script>

<style></style>