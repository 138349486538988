<template>
  <v-app>
    <topmenubar />
    <v-main>
      <section>
        <v-row>
          <v-col cols="12" md="5" class="text-center">
            <h1>Monitoring van de Transformatieopgave Jeugd</h1>
            <p>Versterken, verminderen & voorkomen</p>
            <div class="text-center">
              <v-btn outlined  color="themepurple" class="btn mr-2" :to="'/auth/register'">
                Account Aanmaken
              </v-btn>
              <v-btn color="primary" class="btn " :to="'/auth/login'">
                Inloggen
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import topmenubar from "../components/topmenubar.vue";
export default {
  name: "Home",
  components: {
    topmenubar,
  },
};
</script>

<style scoped>
h1{
  font-size: 55px;
  margin-bottom: 20px;
}
p{
  font-size: 24px;
}
</style>
